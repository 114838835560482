import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { isSafari } from 'react-device-detect';
import theme from '../../constants/theme';
import { MouseContext } from '../../context/MouseContext';
import useWindowSize from '../../helpers/useWindowResize';
import { Link } from 'gatsby-plugin-react-i18next';

const CasiCardWrapper = styled.div`
  max-width: 520px;
  max-height: 680px;
  width: 100%;
  height: 100%;
  padding-top: calc(680 / 520 * 100%);
  position: relative;

  .casiCard-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  flex: 1 0 auto;
  margin-right: 60px;

  .gatsby-image-wrapper {
    height: 100%;
  }

  .info {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    color: ${theme.colorWhite};

    h3 {
      font-size: 28px;
      font-weight: 700;
      line-height: 50px;
    }

    ul {
      li {
        display: inline-block;
        font-size: 12px;
        text-transform: uppercase;
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 1024px) {
    padding-top: calc(310 / 230 * 100%);
    min-width: 230px;
    // width: 280px;
    // height: 460px;
    // padding-top: 0;
    margin-right: 20px;

    .info {
      h3 {
        font-size: 26px;
        line-height: 32px;
      }

      ul {
        li {
          font-size: 10px;
        }
      }
    }

    ${isSafari &&
    `
      width: calc(320px - 60px);

      .info {
        h3 {
          font-size: 22px;
        }
      }
    `}
  }
`;

export default function CasiCard({ card }) {
  const { width } = useWindowSize();
  const { immagineCard, titolo, tags, slug } = card;
  const splittedTags = tags.split(',');
  const titleRef = useRef(null);

  let title = titolo;

  if (width <= 420 && titolo === 'LloydsFarmacia') {
    title = 'Lloyds Farmacia';
  }

  useEffect(() => {
    if (
      titleRef &&
      titleRef.current.scrollWidth > titleRef.current.clientWidth
    ) {
      titleRef.current.style.fontSize = '28px';
    }
  }, [titleRef]);

  return (
    <CasiCardWrapper>
      <div className="casiCard-wrapper hover-card">
        <Link to={'/casi-studio/' + slug}>
          {immagineCard && (
            <GatsbyImage image={getImage(immagineCard.imageFile)} alt="" />
          )}
          <div className="info" role="article">
            <h3 ref={titleRef}>{title}</h3>
            <ul>
              {splittedTags &&
                splittedTags.length > 0 &&
                splittedTags.map((tag, i) => <li key={i}>{tag}</li>)}
            </ul>
          </div>
        </Link>
      </div>
    </CasiCardWrapper>
  );
}
